import "./styles.scss"

import React, { useState } from "react"

import Button from "components/Button"

// import ArrowIcon from "assets/icons/arrow.svg"

const Main = ({ data }) => {
  const [view, setView] = useState("2d")
  const pdf_name = `city-lobzow-${data.slug}.pdf`

  return (
    <section className="apartment-main">
      <div className="container-fluid">
        <div className="apartment-main__back">
          <Button to="/mieszkania/" className="button--bordered">
            <img src={require(`assets/icons/arrow.svg`).default} alt="" />
            lista mieszkań
          </Button>
        </div>

        <div className="apartment-main__row">
          <div className="apartment-main__info">
            <div className="apartment-main__info__items">
              {/* {data?.acfApartment?.price && (
              <div className="apartment-main__list">
                <strong>Cena brutto</strong>
                <h4>
                  {data?.acfApartment?.regularPrice && (
                    <>
                      <h5>
                        {data?.acfApartment?.regularPrice.toLocaleString()}
                      </h5>
                      <br />
                    </>
                  )}
                  {data?.acfApartment?.price.toLocaleString()}
                  <span>PLN</span>
                </h4>
              </div>
            )}

            {data?.acfApartment?.priceForSquareMeter && (
              <div className="apartment-main__list">
                <strong>
                  Cena brutto /m<sup>2</sup>
                </strong>
                <h4>
                  {data?.acfApartment?.priceForSquareMeter.toLocaleString()}
                  <span>
                    PLN/m<sup>2</sup>
                  </span>
                </h4>
              </div>
            )} */}

              <div className="apartment-main__list apartment-main__list--status">
                <strong>Status</strong>
                <h5 className={data.acfApartment.status}>
                  {data.acfApartment.status === "available"
                    ? "dostępne"
                    : data.acfApartment.status === "reserved"
                    ? "zarezerwowane"
                    : "sprzedane"}
                </h5>
              </div>

              {data?.acfApartment?.area && (
                <div className="apartment-main__list">
                  <strong>Metraż</strong>
                  <h4>
                    {data?.acfApartment?.area}
                    <span>
                      m<sup>2</sup>
                    </span>
                  </h4>
                </div>
              )}

              {data?.acfApartment?.roomsnumber && (
                <div className="apartment-main__list">
                  <strong>Ilość pokoi</strong>
                  <h4>{data?.acfApartment?.roomsnumber}</h4>
                </div>
              )}

              <div className="apartment-main__list">
                <strong>Piętro</strong>
                <h4>
                  {data?.acfApartment?.floor
                    ? data.acfApartment.floor === -1
                      ? "Parter niski"
                      : data.acfApartment.floor
                    : "Parter wysoki"}
                </h4>
              </div>

              {data?.acfApartment?.location && (
                <div className="apartment-main__list">
                  <strong>Ekspozycja</strong>
                  <h4>{data?.acfApartment?.location}</h4>
                </div>
              )}

              {data?.acfApartment?.roomsList && (
                <div className="apartment-list__rooms">
                  {data?.acfApartment?.roomsList?.map((item, index) => (
                    <div className="apartment-list__rooms-item" key={index}>
                      {index + 1}. {item.name}
                      <strong>
                        {item.area}m<sup>2</sup>
                      </strong>
                    </div>
                  ))}
                </div>
              )}

              {require(`assets/pdfs/${pdf_name}`).default && (
                <div className="apartment-list__cta">
                  <a
                    className="button"
                    href={require(`assets/pdfs/${pdf_name}`).default}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Karta mieszkania
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className="apartment-main__view">
            <div className="apartment-main__nav">
              {view === "2d" && (
                <div className="apartment-main__nav-compass">
                  <img
                    src={require("assets/images/ico-compass.png").default}
                    alt=""
                  />
                </div>
              )}
              <div className="apartment-main__nav-switches">
                <span>Widok</span>
                {data?.acfApartment?.visualization2d?.sourceUrl && (
                  <button
                    onClick={() => setView("2d")}
                    className={view === "2d" ? "current" : ""}
                  >
                    2D
                  </button>
                )}
                {data?.acfApartment?.visualization3d?.sourceUrl && (
                  <button
                    onClick={() => setView("3d")}
                    className={view === "3d" ? "current" : ""}
                  >
                    3D
                  </button>
                )}
                {data?.acfApartment?.visualization360 && (
                  <button
                    onClick={() => setView("360")}
                    className={view === "360" ? "current" : ""}
                  >
                    360°
                  </button>
                )}
                {data?.acfApartment?.virtualWalk && (
                  <a
                    href={data?.acfApartment?.virtualWalk}
                    target="_blank"
                    rel="noreferrer"
                  >
                    wirtualny spacer
                  </a>
                )}
              </div>
            </div>

            <div className="apartment-main__image">
              {view === "2d" && (
                <img
                  src={data?.acfApartment?.visualization2d?.sourceUrl}
                  alt=""
                />
              )}
              {view === "3d" && (
                <img
                  src={data?.acfApartment?.visualization3d?.sourceUrl}
                  alt=""
                />
              )}
              {view === "360" && (
                <iframe
                  title="City Łobzów"
                  src={data?.acfApartment?.visualization360}
                  width="100%"
                  height="700px"
                ></iframe>
              )}
            </div>

            <div className="apartment-list__floor">
              {data?.acfApartment?.floorView && (
                <img
                  src={data?.acfApartment?.floorView.sourceUrl}
                  alt="{title}"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
